import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/Tobias/Desktop/affiliate/sites/vandringsled/gatsby/node_modules/gatsby-theme-base/src/components/layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ul>
      <li parentName="ul">
        <Link to="vilket-typ-av-talt-for-vandringen" mdxType="Link">
  Vilket typ av tält för vandringen?
        </Link>
      </li>
      <li parentName="ul">
        <Link to="basta-kupoltaltet-2020" mdxType="Link">Bästa kupoltältet 2020</Link>
      </li>
      <li parentName="ul">
        <Link to="basta-tunneltaltet-2020" mdxType="Link">Bästa tunneltältet 2020</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      